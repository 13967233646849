<template>
  <div class="container">
    <div class="mt-8">
      <h1
        v-if="$store.getters.live_data.length"
        class="display-4 text-center text-primary"
      >
        Live Data <i class="fa fa-circle text-danger"></i>
      </h1>
      <div v-if="$store.getters.live_data.length == 0">
        <h1 class="display-3 text-primary">No Live data found</h1>
        <p class="lead">
          You can request live data on search detail page.
        </p>
        <p class="text-center">
          <base-button @click="$router.push('/search')" size="lg" type="info"
            >Search <i class="fa fa-search"></i
          ></base-button>
        </p>
      </div>
      <div v-else>
        <div
          v-for="(data, index) in this.$store.getters.live_data"
          :key="index"
          class="result-list-item mb-3"
        >
          <div class="row">
            <div class="col">
              {{ index + 1 }}.
              <router-link
                :to="`/search/detail/${data.latest_record.length &gt; 0 &amp;&amp; data.response_status === 'COMPLETED' ? data.latest_record[0] : data.query}-${data.country_code}`"
                ><strong
                  >{{ data.fe_query }}
                  {{ data.fe_query ? "-" : "" }}
                  {{ country_name(data.country_code) }}</strong
                ></router-link
              >
            </div>
            <div class="col">
              <badge
                rounded
                style="text-transform:none;"
                :type="
                  data.response_status == 'ACCEPTED' ||
                  data.response_status == 'COMPLETED'
                    ? 'success'
                    : data.response_status == 'INPROGRESS'
                    ? 'info'
                    : 'danger'
                "
                class="float-right mr-2"
                >{{ get_status(data.response_status) }}</badge
              >
            </div>
          </div>
          <div class="ml-3">
            <span class="text-primary"
              >Requested at {{ format_date(data.request_time) }}</span
            >
          </div>
          <div class="mt-2" v-if="data.response_status == 'COMPLETED'">
            <base-button
              @click="show_detail(data)"
              size="sm"
              outline
              type="success"
              >Show Detail</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data: function() {
    return {
      country_list: [],
      time_format: "YYYY-MM-DD hh:mm:ssTZZ",
    };
  },
  metaInfo: {
    title: "Live Data"
  },
  mounted() {
    this.updateCountryList();
  },
  methods: {
    format_date(d) {
      return moment(d,this.time_format).format("lll");
    },
    get_status(status) {
      switch (status) {
        case "INPROGRESS":
          return "Processing";
        case "ACCEPTED":
          return "Processing";
        case "REJECTED":
          return "Rejected";
        case "ERROR":
          return "Error";
        case "COMPLETED":
          return "Completed";
      }
    },
    show_detail(data) {
      this.$router.push(
        `/search/detail/${data.query}-${data.country_code}?live=true`
      );
    },
    updateCountryList() {
      this.$axios.get("search/country-list/").then(res => {
        this.country_list = res.data;
      });
    },
    country_name(id) {
      var name = "";
      if (!this.country_list) {
        return name;
      }
      this.country_list.forEach(e => {
        if (e.record_number == id) {
          return (name = e.country_name);
        }
      });
      return name;
    }
  }
};
</script>
<style scoped>
.result-list-item {
  border: 0.0625rem solid #e9ecef;
  padding: 10px;
  border-radius: 10px;
}
.result-list-item:hover {
  background-color: #e9ecef;
}
</style>
